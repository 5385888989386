<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="content"
          append-icon="mdi-calendar"
          readonly
          :disabled="!canAnswer || formSubmit"
          v-on="on"
          :error="
            $v.content.$anyError &&
              canAnswer &&
              !formSubmit &&
              question.is_required
          "
        ></v-text-field>
      </template>
      <v-date-picker
        v-if="option.config.type === 'dateonly'"
        v-model="content"
        :min="dateToday"
        @input="input"
        @change="submit"
      />
      <v-time-picker
        v-else-if="option.config.type === 'timeonly'"
        format="24hr"
        v-model="content"
        @change="submit"
      />
    </v-menu>
    <div
      class="error--text"
      v-if="
        $v.content.$anyError && canAnswer && !formSubmit && question.is_required
      "
    >
      Required
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'OptionDateTime',

  validations: {
    content: {
      required
    }
  },

  data() {
    return {
      content: '',
      menu: false
    }
  },

  props: {
    value: {
      type: String,
      default: ''
    },

    disabled: {
      default: false
    },

    formId: {
      type: String
    },

    question: {
      type: Object
    },

    optionId: {
      type: String
    },

    label: {
      type: String
    },

    isRequired: {
      default: false,
      type: Boolean
    },
    formSubmit: {
      type: Boolean
    }
  },

  watch: {
    value: {
      handler(newValue) {
        if (newValue) {
          this.content = newValue
        }
      },
      immediate: true
    }
  },

  computed: {
    questionId() {
      return this.question.id
    },

    type() {
      return this.option.type
    },

    option() {
      return this.question.options.find(option => option.id === this.optionId)
    },
    canAnswer() {
      if (this.question.hasOwnProperty('can_answer')) {
        return this.question.can_answer
      }
      return true
    },
    dateToday() {
      let d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear()

      if (month.length < 2) month = '0' + month
      if (day.length < 2) day = '0' + day

      return [year, month, day].join('-')
    }
  },

  mounted() {
    this.initErrorHandler()
    this.setDefaultDate()
    this.submit()
  },

  methods: {
    input() {
      this.menu = false
      this.$emit('input', this.content)
      this.$v.content.$touch()
    },

    setDefaultDate() {
      this.$emit('input', this.content)
    },

    initErrorHandler() {
      let self = this
      this.$root.$on('create-form-error', () => {
        self.$v.content.$touch()
      })
    },

    async submit() {
      if (this.content.length) {
        const payload = {
          form_id: this.formId,
          question_id: this.question.id,
          option_id: this.option.id,
          answer: this.content
        }

        if (this.$_isPublicForm) delete payload.form_id

        await this.$_submitSingleAnswer({
          data: payload,
          isPublicForm: this.$_isPublicForm
        })
      }
    }
  }
}
</script>
